'use client';

import { useEffect } from 'react';

import logSentryError from 'utils/logSentry';

import Error from './_error';

export default function GlobalError({
  error,
  _reset,
}: {
  error: Error & { digest?: string };
  _reset: () => void;
}) {
  useEffect(() => {
    logSentryError('[Root Layout] global error', error);
  }, [error]);

  return (
    <html lang="en">
      <body>
        <Error message="Something went wrong" />
      </body>
    </html>
  );
}

import get from 'lodash/fp/get';

import type { SeverityLevel } from '@sentry/nextjs';
import * as Sentry from '@sentry/nextjs';
import { isError } from 'types/predicates';

const createDefaultFormatter = (message: string) => (error: Error) => {
  return `${message}: ${error?.toString()}`;
};

type ErrorFormatter = (err: Error) => string;
type Options = {
  logLevel?: SeverityLevel;
  userId?: string;
};

const logSentryError = (
  errorMessageFormatter: string | (() => string),
  error: unknown,
  options?: Options,
) => {
  if (typeof error !== 'string' && !isError(error)) return;

  const logLevel = get('logLevel', options) ?? 'error';
  const userId = get('userId', options);
  const errorObject = isError(error) ? error : new Error(error);

  const errorFormatter: ErrorFormatter =
    typeof errorMessageFormatter === 'function'
      ? errorMessageFormatter
      : createDefaultFormatter(errorMessageFormatter);

  Sentry.withScope((scope) => {
    scope.setLevel(logLevel);

    if (userId) {
      Sentry.setUser({ userId });
    }

    errorObject.message = errorFormatter(errorObject);
    Sentry.captureException(errorObject);
  });
};

export default logSentryError;

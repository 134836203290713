// https://www.totaltypescript.com/iterate-over-object-keys-in-typescript
export function isKey<T extends object>(obj: T, key: PropertyKey): key is keyof T {
  return key in obj;
}

export function isObjectType<T>(value: T | object): value is NonNullable<object> {
  return typeof value === 'object' && value !== null;
}

export function isError(err: unknown): err is Error {
  return err instanceof Error;
}

'use client';

import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';

const Root = styled.div`
  margin-top: 50px;
  text-align: center;
`;

const Title = styled.h1`
  margin-top: 20px;
`;

const StyledLink = styled.a`
  color: ${theme.colors.neutral['900']};
  text-decoration: underline;
  cursor: pointer;
`;

type ErrorProps = {
  href?: string;
  message?: string;
  linkCTA?: string;
};

const Error = ({ href = '/', message, linkCTA = 'Go to Homepage' }: ErrorProps) => (
  <Root data-testid="error">
    <h1>{message ?? 'Oops, something went wrong...'}</h1>

    <Title>
      <StyledLink href={href}>{linkCTA}</StyledLink>
    </Title>
  </Root>
);

export default Error;
